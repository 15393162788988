import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { BackButton } from '../../components/library'

function button() {
    return (
        <LibraryWrapper>
            <BackButton to="/components">
                Go Back Gatsby Link
            </BackButton>
            <hr />
            <BackButton onClick={e => console.log('GOING BACK', e)}>
                Go Back Click Event
            </BackButton>

        </LibraryWrapper>
    )
}
export default button
